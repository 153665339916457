.footer-links {
    // Vars

    // Support

    // Module
    & {
        .footer-links--title {
            @include hide-visually;
        }

        .footer-links--column-title {
            color: $c--brand-light-blue;
            font-size: $fz--x-small;
            line-height: $lh--x-small;
            margin-bottom: 8px;
        }

        .footer-links--row {
            @include mq('<=md') {
                padding: 22px 0;
            }
        }

        .footer-links--col {
            @include grid--span((ratio: 1/4));
            @include grid--span((ratio: 1/2, mq: 'md'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
            overflow: hidden;

            @include mq('>md') {
                display: flex;
                justify-content: center;
            }
        }

        .footer-links--image-wrap {
            display: flex;
            flex-direction: column;
        }

        .footer-links--image {
            display: inline-block;
            margin-top: 12px;
        }

        .footer-links--link-items {
            display: inline-flex;
            flex-flow: column nowrap;
            overflow: hidden;

            @include mq('<=md') {
                padding: 22px 0;
            }
        }

        .footer-links--link-item {
            display: inline-flex;
            flex: 0 0 auto;
            font-size: 0;

            ~ .footer-links--link-item {
                .footer-links--image-wrap {
                    margin-top: 40px;
                }
            }
        }

        .footer-links--link {
            display: flex;
            flex-flow: row nowrap;
            font-size: $fz--x-small;
            line-height: 2.4;

            @include has-focus(false) {
                .footer-links--link-chevron {
                    transform: translateX(-6px);
                }
            }

            @include has-focus(true) {
                .footer-links--link-icon,
                .footer-links--link-chevron {
                    color: $c--monochrome-white;
                }

                .footer-links--link-chevron {
                    transform: none;
                }
            }
        }

        .footer-links--link-icon,
        .footer-links--link-chevron {
            display: inline-block;
            color: $c--brand-light-blue;
            margin-top: 10px; // NOTE: To take into account the line-height
        }

        .footer-links--link-icon {
            margin-right: 10px;
            transition: color $trs--base;
        }

        .footer-links--link-chevron {
            margin-left: 12px;
            transition: transition-props($trs--base, transform, color);
        }
    }

    // Facets

    // States
}
