.body-regions-image {
    // Vars

    // Support

    // Module
    & {
        display: inline-block;
        width: 100% !important; // stylelint-disable-line declaration-no-important
        height: 100% !important; // stylelint-disable-line declaration-no-important
        max-height: 100%;
        max-width: 100%;

        .body-regions-image--path {
            fill: currentColor;
            color: $c--brand-light-blue;

            &.body-regions-image--path__region {
                transition: color $trs--region;
            }

            &.body-regions-image--path__subregion {
                transition: color $trs--region;
            }
        }
    }

    // Facets
    & {
        // In CUI
        &.body-regions-image__in-cui {
            width: auto !important; // stylelint-disable-line declaration-no-important

            // NOTE: This hack is not necessary for MSEdge 18+ but there is no clean way to target only this browser:
            @include at-root(html, '.edge') {
                // NOTE: Fix height due to a flexbox bug in MSEdge 17-
                height: 223px !important; // stylelint-disable-line declaration-no-important
            }
        }

        // In dashboard
        &.body-regions-image__in-dashboard {
            //width: auto !important; // stylelint-disable-line declaration-no-important
        }

        &.body-regions-image__base,
        &.body-regions-image__in-cui,
        &.body-regions-image__interactive {
            .body-regions-image--path {
                &.body-regions-image--path__is-hidden {
                    opacity: 0;
                    pointer-events: none;
                }

                &.body-regions-image--path__is-active {
                    color: $c--brand-dark-blue;
                }

                &.body-regions-image--path__is-interactive {
                    cursor: pointer;

                    /* stylelint-disable max-nesting-depth */
                    & {
                        @include has-focus(true) {
                            color: color-tint($c--brand-light-blue, 20%);
                        }
                    }

                    &.body-regions-image--path__is-active {
                        @include has-focus(true) {
                            color: color-shade($c--brand-dark-blue, 20%);
                        }
                    }
                    /* stylelint-enable max-nesting-depth */
                }
            }
        }

        &.body-regions-image__interactive {
            .body-regions-image--path.body-regions-image--path__region {
                color: color-tint($c--brand-light-blue, 65%);

                @include has-focus(true) {
                    color: color-tint($c--brand-light-blue, 50%);
                }
            }

            .body-regions-image--path.body-regions-image--path__subregion.body-regions-image--path__is-hidden.body-regions-image--path__is-active {
                opacity: 0.5;
                color: color-tint($c--brand-light-blue, 20%);
            }
        }
    }

    // States
}
