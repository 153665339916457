/// Secondary
///
@mixin btn--secondary-cui() {
    // Vars

    // Support

    // Module
    & {
        @include btn--all();
        @include btn--shadowed(2px, $c--brand-dark-blue, $sz--border-width-base);
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--shadowed-target-both() {
                @include btn--secondary-cui-base();
            }
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--shadowed-target-both() {
                @include btn--secondary-cui-focus();
            }
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--shadowed-target-both() {
                @include btn--secondary-cui-active();
            }
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--shadowed-target-both() {
                @include btn--secondary-cui-disabled();
            }
        }
    }
}

/// Base
///
@mixin btn--secondary-cui-base() {
    color: $c--monochrome-white;
    background-color: $c--brand-blue;
    border-color: transparent;
}

/// Focus
///
@mixin btn--secondary-cui-focus() {
    background-color: color-tint($c--brand-blue, 20%);
}

/// Active
///
@mixin btn--secondary-cui-active() {
    //
}

/// Disabled
///
@mixin btn--secondary-cui-disabled() {
    color: $c--monochrome-gray;
    background-color: $c--monochrome-light-gray;
}
