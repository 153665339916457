.legal-footer-link {
    // Vars
    $sz--height: 20px;

    // Support

    // Module
    & {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        @include mq('>md') {
            height: $sz--height;

            ~ .legal-footer-link {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    height: $sz--height;
                    transform: translateY(-50%);
                    border-left: 1px solid $c--brand-dark-blue;
                }
            }
        }


        .legal-footer-link--element {
            display: block;
            font-size: $fz--x-small;
            line-height: 1;
            transition: color $trs--base;

            @include mq('<=md') {
                line-height: 2.4;
            }

            @include has-focus(true) {
                color: mix($c--monochrome-white, $c--brand-dark-gray-blue, 80%);
            }
        }
    }

    // Facets

    // States
}
