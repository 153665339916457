.cui-message-display-edit-btn {
    // Vars

    // Support

    // Module
    & {
        @include is-visible(true, $trs--base-speed);
        position: relative;
        top: -$sz--border-width-base;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: (8px + $sz--border-width-base) 8px 0;

        font-size: $fz--xx-small;
        line-height: $lh--xx-small;
        color: $c--brand-dark-blue;
        cursor: pointer;

        .cui-message-display-edit-btn--text {
            margin-right: 8px;
            opacity: 1;
            transition: opacity $trs--base;
        }
    }

    // Facets
    & {
        &.cui-message-display-edit-btn__manager-is-editing-completed-messages {
            @include is-visible(false, $trs--base-speed);
            pointer-events: none;
        }
    }

    // States
}
