.info-modal {

    // Module
    & {
        .info-modal--container.info-modal--container {
            max-width: $sz--info-modal-width;

            @include at-root(html, '.ie11') {
                box-sizing: content-box;
                max-width: calc(#{$sz--info-modal-width} - 0.1px);

                 stylelint-disable selector-max-universal
                > * {
                    box-sizing: border-box;

                    * {
                        box-sizing: inherit;
                    }
                }
                // stylelint-enable selector-max-universal
            }
        }

        .info-modal--header {
            padding: 14px 16px;
            background-color: $c--monochrome-white;
            border-top-left-radius: $sz--border-radius-base;
            border-top-right-radius: $sz--border-radius-base;
            overflow: hidden;
            border-bottom: 1px solid #C4C4C4;

            @include at-root(html, '.edge') {
                border-radius: 0;
            }
        }

        .info-modal--body {
            min-height: 90px;
            padding: 14px 16px;
            background-color: $c--monochrome-white;
            text-align: center;
            overflow: hidden;

            &:last-child {
                border-bottom-left-radius: $sz--border-radius-base;
                border-bottom-right-radius: $sz--border-radius-base;

                @include at-root(html, '.edge') {
                    border-radius: 0;
                }
            }
        }

        .info-modal--title {
            color: $c--brand-light-blue;
            text-align: center;
        }


        .info-modal--description {
            @include type--copy;
            text-align: left;
        }

        .info-modal--link {
            @include type--copy;
            text-align: left;
            color: $c--brand-light-blue;
        }


        .info-modal--question {
            @include type--copy;
            text-align: left;
            color: $c--brand-light-blue;
            font-weight: 600;
        }


        .info-modal--footer {
            $sz--bg-offset: ($sz--border-width-base * 2);

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            background-color: $c--monochrome-white;
            padding-bottom: 24px;
        }

        .info-modal--btn {
            flex: 0 1 50%;
            overflow: hidden;
            box-shadow: none;
            margin-left: auto;
        }
    }

    // Facets

    // States
}
