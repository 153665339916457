/// Field styles
///
@mixin form-field--cui-file($sz--box-border) {
    // Vars

    // Module
    & {
        .form-field--input {
            @include hide-visually;
        }

        .form-field--label-wrap {
            margin-bottom: 0;
        }

        .form-field--label {
            padding-left: 0;
            transform: translateY(2px);

            transition: margin $trs--base;
        }

        .form-field--icon {
            margin-left: 12px;
            transition: margin $trs--base;
        }

        .form-field--dropzone {
            display: none;
        }
    }

    // States
    & {
        // Is dragging
        @include at-root('.viewport-root', '.viewport-root__is-dragging') {
            .form-field--label-wrap.form-field--label-wrap {
                &::after {
                    background-color: color-tint($c--brand-blue, 10%);
                }
            }

            .form-field--label {
                margin: 22px 0 22px 24px;
            }

            .form-field--icon {
                margin: 22px 24px 22px 12px;
            }

            .form-field--dropzone {
                z-index: 1;
                position: fixed;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        // Base
        @include form-field--apply-states(()) {
            .form-field--input {
                // Base
                @include form-field--bool-apply-states(()) {
                    @include form-field--cui-file-base();
                }

                // Focus
                @include form-field--bool-apply-states(('disabled': false, 'focus': true)) {
                    @include form-field--cui-file-focus(
                        $sz--box-border: $sz--box-border
                    );
                }
            }
        }
    }
}

/// Base
///
@mixin form-field--cui-file-base() {
    color: $c--monochrome-white;

    &.form-field--label-wrap {
        &::after {
            background-color: $c--brand-dark-blue;
        }

        &::before {
            background-color: color-shade($c--brand-dark-blue, 10%);
        }
    }
}

/// Focus
///
@mixin form-field--cui-file-focus($sz--box-border) {
    &.form-field--label-wrap {
        transform: translate(0);

        &::before, &::after {
            transform: translate(-$sz--box-border, -$sz--box-border);
        }
    }
}
