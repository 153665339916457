.payment-table {
    // Vars

    // Support

    // Module
    & {
        width: 100%;
        color: $c--monochrome-dark-gray;

        .payment-table--tfoot .payment-table--col {
            padding-top: 16px;
            border-top: 1px solid $c--monochrome-light-gray;

            &.payment-table--col__title,
            &.payment-table--col__value {
                @include type--copy-large;
            }
        }

        .payment-table--row.payment-table--row__tbody {
            & {
                .payment-table--col {
                    padding: 7px 0;
                }
            }

            &:first-child {
                .payment-table--col {
                    padding-top: 0;
                }
            }

            &:last-child {
                .payment-table--col {
                    padding-bottom: 16px;
                }
            }
        }

        .payment-table--col {
            &.payment-table--col__title {
                @include type--copy-x-small;
            }

            &.payment-table--col__value {
                @include ff--base-semi-bold;
                @include type--copy-x-small;
                text-align: right;
                white-space: nowrap;
            }
        }

        .payment-table--note {
            @include type--copy-xx-small;
            display: block;
            margin-top: 4px;
            color: $c--monochrome-gray;
        }
    }

    // Facets
    & {
        &.payment-table__in-pdf {
            width: 60mm;
            margin-top: 4mm;
            color: $c--monochrome-dark-gray;

            .payment-table--tfoot .payment-table--col {
                padding-top: 2mm;

                &.payment-table--col__title,
                &.payment-table--col__value {
                    font-size: 9pt;
                    line-height: 1.2;
                }
            }

            .payment-table--row.payment-table--row__tbody {
                & {
                    .payment-table--col {
                        padding: 1mm 0;
                    }
                }

                &:first-child {
                    .payment-table--col {
                        padding-top: 0;
                    }
                }

                &:last-child {
                    .payment-table--col {
                        padding-bottom: 2mm;
                    }
                }
            }

            .payment-table--col {
                &.payment-table--col__title {
                    font-size: 9pt;
                    line-height: 1.2;
                }

                &.payment-table--col__value {
                    @include ff--base-semi-bold;
                    font-size: 9pt;
                    line-height: 1.2;
                    text-align: right;
                    white-space: nowrap;
                }
            }

            .payment-table--note {
                font-size: 7pt;
                line-height: 1.2;
                margin-top: 0;
                color: $c--monochrome-gray;
            }
        }
    }

    // States
}
