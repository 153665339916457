////
/// Grid library for all your basic grid needs!
////

/// Dependencies and components
///
@import 'color__vars';
@import 'color__helpers';

/// Looks through all color definitions and returns the stored value
/// @param {string} $subset - The subset in which the color is stored
/// @param {number|string} $idx - The key as a name or index value
///
@function color($subset, $idx) {
    $map: map-get($color-maps, $subset);
    $color: transparent;

    @if (type-of($idx) == 'number') {
        $color: nth($map, $idx);
    } @else {
        $color: map-get($map, $idx);
    }

    @if (type-of($color) != 'color') and (not list-contains((inherit initial null), $color)) {
        @error 'Color `#{$idx}` in map `#{$subset}` not found!';
    }

    @return $color;
}

/// Color var definitions (for easier autocompletion)
///
$c--monochrome-dark-gray: color(monochrome, 'dark-gray');
$c--monochrome-gray: color(monochrome, 'gray');
$c--monochrome-silver: color(monochrome, 'silver');
$c--monochrome-light-gray: color(monochrome, 'light-gray');
$c--monochrome-white: color(monochrome, 'white');
$c--monochrome-black: color(monochrome, 'black');

$c--brand-light-blue: color(brand, 'light-blue');
$c--brand-blue: color(brand, 'blue');
$c--brand-dark-blue: color(brand, 'dark-blue');
$c--brand-light-gray-blue: color(brand, 'light-gray-blue');
$c--brand-dark-gray-blue: color(brand, 'dark-gray-blue');
$c--brand-salmon: color(brand, 'salmon');

$c--label-text: color(label, 'text');
$c--label-link: color(label, 'link');
$c--label-selection: color(label, 'selection');

$c--signal-info: color(signal, 'info');
$c--signal-error: color(signal, 'error');
$c--signal-warning: color(signal, 'warning');
$c--signal-success: color(signal, 'success');

/// Finally, check if the list of color vars is complete
///
@include color--check-var-availability();
