.cui-message-display {
    // Vars

    // Support

    // Module
    & {
        @include mq('>md') {
            width: 408px;
        }

        @include mq(('<=md' '>xs')) {
            width: 300px;
        }

        @include mq('xs') {
            width: calc(100% - 44px);
        }

        .cui-message-display--bubble-wrap,
        .cui-message-display--bubble {
            min-width: $sz--bubble-initial-size;
            min-height: $sz--bubble-initial-size;
        }

        .cui-message-display--bubble-wrap {
            position: relative;
            display: inline-flex;
            margin-bottom: $sz--border-width-base;
        }

        .cui-message-display--bubble {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $c--monochrome-white;
            border-radius: $sz--border-radius-cui;
        }

        .cui-message-display--content-wrap {
            z-index: 1;
            position: relative;
        }

        .cui-message-display--edit {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
        }
    }

    // Facets
    & {
        &.cui-message-display__odc {
            .cui-message-display--bubble {
                left: 0;
                border-top-left-radius: 0;
                box-shadow: 0 $sz--border-width-base 0 0 $c--brand-light-blue;
            }
        }

        &.cui-message-display__me {
            .cui-message-display--bubble {
                right: 0;
                border-top-right-radius: 0;
                box-shadow: 0 $sz--border-width-base 0 0 $c--brand-blue;
            }
        }

        &.cui-message-display__has-errors {
            .cui-message-display--bubble {
                box-shadow: 0 2px 0 0 $c--signal-error;
            }
        }

        &.cui-message-display__cui-body-regions {
            .cui-message-display--bubble-wrap,
            .cui-message-display--content-wrap,
            .cui-message-display--content {
                @include at-root(html, '.ie11') {
                    width: 100%;
                }

                @include at-root(html, '.edge') {
                    width: 100%;
                }
            }
        }
    }

    // States
}
