/// Field styles
///
@mixin form-field--cui-input($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--border-width) {
    // Vars
    $sz--min-height: 54px;
    $sz--border-radius: 2px;
    $sz--submit-button-width: 52px; // Within the CUI, a submit button is rendered above input fields and textareas

    // Support

    // Module
    & {
        .form-field--input-container {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                display: block;
                width: 100%;
                height: 0;
                border-bottom: 1px solid transparent;
                transition: border-color $trs--base;
            }
        }

        .form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .form-field--input {
            display: inline-block;
            width: 100%;
            min-height: $sz--min-height;
            padding: $sz--padding-top $sz--submit-button-width $sz--padding-bottom $sz--padding-x;
            border-radius: $sz--border-radius;
            box-shadow: 0 $sz--border-width-base 0 0 $c--brand-dark-blue;
            margin-bottom: $sz--border-width-base;
            border: none;
            font-size: $fz--medium;
            line-height: $lh--medium;
            transition: transition-props($trs--base, box-shadow, $trs--common-property-list...);

            @include placeholder() {
                color: $c--monochrome-gray;
            }
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--cui-input-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--cui-input-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--cui-input-disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--cui-input-error();
        }
    }
}

/// Base
///
@mixin form-field--cui-input-base() {
    .form-field--title-label {
        color: $c--monochrome-gray;
    }

    .form-field--input {
        color: $c--label-text;
        background-color: $c--monochrome-white;

        @include placeholder() {
            color: $c--monochrome-silver;
        }
    }
}

/// Focus
///
@mixin form-field--cui-input-focus() {
    .form-field--input {
        border-color: $c--signal-info;
        box-shadow: 0 2px 0 0 $c--signal-info;
    }

    .form-field--title-label {
        color: $c--signal-info;
    }
}

/// Disabled
///
@mixin form-field--cui-input-disabled() {
    .form-field--title-label {
        @include is-selectable(false);
        color: $c--monochrome-silver;
        cursor: default;
    }

    .form-field--input {
        @include is-selectable(false);
        color: $c--monochrome-silver;
        border-color: $c--monochrome-silver;
        background-color: $c--monochrome-light-gray;
        box-shadow: 0 2px 0 0 $c--monochrome-silver;
        cursor: default;

        @include placeholder() {
            color: transparent;
        }
    }
}

/// Error
///
@mixin form-field--cui-input-error() {
    .form-field--input {
        border-color: $c--signal-error;
        box-shadow: 0 2px 0 0 $c--signal-error;
    }

    .form-field--title-label {
        color: $c--signal-error;
    }
}
