.detail-hint.detail-hint {
    // Vars

    // Support

    // Module
    & {
        @include type--copy-x-small();
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-top: 4px;
        transition: $trs--common-props;

        .detail-hint--text {
            display: inline-block;
        }

        .detail-hint--icon {
            display: inline-block;
            margin-left: 4px;
        }
    }

    // Facets
    & {
        &.detail-hint__base {
            color: $c--monochrome-gray;

            @include has-focus(true) {
                color: $c--brand-light-blue;
            }
        }

        &.detail-hint__white {
            color: $c--monochrome-white;

            @include has-focus(true) {
                color: rgba($c--monochrome-white, 0.8);
            }
        }

        &.detail-hint__blue {
            color: $c--brand-light-blue;

            @include has-focus(true) {
                color: $c--brand-dark-blue;
            }
        }
    }

    // States
}
