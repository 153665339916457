.testimonial-item {
    // Vars
    $sz--image: 66px;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;

        @include at-root(html, '.ie11') {
            display: block;
            height: 100%;
        }

        @include at-root(html, '.ie10') {
            display: block;
            height: 100%;
        }

        .testimonial-item--wrapper {
            display: flex;
            flex-flow: column nowrap;

            @include at-root(html, '.ie11') {
                height: 100%;
            }

            @include at-root(html, '.ie10') {
                height: 100%;
            }
        }

        .testimonial-item--quote {
            @include type--copy-large();
            flex: 1 0 auto;
            color: $c--monochrome-gray;

            &::before {
                content: '«';
            }

            &::after {
                content: '»';
            }
        }

        .testimonial-item--author {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            flex: 0 1 auto;
            margin-top: 32px;

            @include mq('<=sm') {
                margin-top: 24px;
            }
        }

        .testimonial-item--image-wrap {
            position: relative;
            flex: 0 0 $sz--image;
            padding-top: $sz--image;
            margin-right: 36px;

            @include mq('<=sm') {
                margin-right: 16px;
            }
        }

        .testimonial-item--image {
            @include overlay();
        }

        .testimonial-item--name {
            color: $c--brand-light-blue;
        }

        .testimonial-item--additional-info {
            @include type--copy-small();
            color: $c--monochrome-gray;
        }
    }

    // Facets

    // States
}
