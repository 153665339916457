@mixin bg--title() {
    background-color: $c--brand-light-blue;
    background-image: linear-gradient(to bottom, rgba($c--brand-salmon, 0.2), rgba($c--brand-blue, 0.2));
}

@mixin bg--cui() {
    background-color: color-tint($c--brand-light-blue, 20%);
    background-image: linear-gradient(to bottom, rgba($c--brand-salmon, 0.2), rgba($c--brand-blue, 0.2));
}

@mixin bg--testimonial() {
    background-color: rgba($c--monochrome-light-gray, 0.33);
}

@mixin bg--footer() {
    background-color: $c--brand-dark-blue;
    background-image: linear-gradient(-260deg, rgba(255, 255, 255, 0), #3f8fa2);
}

@mixin bg--pattern() {
    $sz--pattern: $sz--plus-pattern-unitless;

    background-image:
        url('#{plus--svg(rgba($c--monochrome-black, 0.04), $sz--pattern * 2, $sz--pattern, 8)}'),
        url('#{plus--svg(rgba($c--monochrome-black, 0.04), $sz--pattern * 2, $sz--pattern, 8)}');

    background-position:
        top left,
        top ($sz--pattern * 0.5px) left ($sz--pattern * 1px);
}
