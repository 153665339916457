.process {
    // Vars
    $sz--list-item-offset: 128px;
    $sz--line-offset-small: 12px;
    $sz--line-padding: 4px;
    $sz--line-dot: 5px;
    $sz--illustration-mobile: 250px;

    // Support

    // Module
    & {
        @include cms--padded-plugin();
        z-index: 0;
        position: relative;
        overflow: hidden;

        .process--intro {
            color: $c--brand-light-blue;
        }

        .process--separator {
            display: block;
            margin: 12px 0 36px;
        }

        .process--list-item {
            position: relative;
        }

        .process--list-item ~ .process--list-item {
            padding-top: $sz--list-item-offset;

            .process--item {
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    display: block;
                    width: $sz--line-dot;
                    height: $sz--list-item-offset - (2 * $sz--line-padding);
                    margin-bottom: $sz--line-padding;

                    background-position-y: $sz--line-padding / 2;
                    background-image: url('#{dot--svg($c--brand-light-blue)}');

                    @include mq('<=sm') {
                        margin-bottom: $sz--line-padding + $sz--line-offset-small;
                    }
                }
            }
        }

        .process--row-item {
            align-items: center;
        }

        .process--col-illustration {
            @include grid--span((ratio: 1/3, mq: '>lg'));
            @include grid--span((ratio: 2/5, mq: ('>sm' '<=lg')));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }

        .process--col-description {
            @include grid--span((ratio: 1/2, mq: '>lg'));
            @include grid--span((ratio: 3/5, mq: ('>sm' '<=lg')));
            @include grid--span((ratio: 1/1, mq: '<=sm'));

            @include mq('<=sm') {
                margin-top: 8px - 60px;
            }
        }

        .process--col-description-wrap {
            @include mq('<=sm') {
                padding: $sz--line-offset-small 0;
            }
        }

        .process--row-illustration-inner {
            justify-content: center;
        }

        .process--col-illustration-inner {
            @include grid--span((ratio: 1/1));
            @include grid--span((ratio: 1/1, mq: '<=sm'));

            @include mq('<=sm') {
                //padding-bottom: 0;
            }
        }

        .process--col-illustration-inner-wrap {
            @include mq('<=sm') {
                padding-top: $sz--line-offset-small;
            }
        }

        .process--item {
            position: relative;
            width: 100%;
            padding-top: 100%;
            //background-image: linear-gradient(to bottom, rgba(#cce6e7, 0.8), rgba($c--brand-light-blue, 0.8));

            @include mq('>sm') {
                width: 250px;
                padding-top: 250px;
                margin: 0 auto;
            }

            @include mq('<=sm') {
                width: $sz--illustration-mobile;
                height: $sz--illustration-mobile;
                padding-top: 0;
                margin: 0 auto;
            }
        }

        .process--mask {
            @include overlay();

            &::after {
                $sz--mask-border: 4px;

                @include overlay();
                content: '';
                pointer-events: none;
                z-index: 2;
                width: calc(100% + #{ $sz--mask-border });
                height: calc(100% + #{ $sz--mask-border });
                border-radius: 50%;
                border: $sz--mask-border solid $c--monochrome-white;
                transform: translate($sz--mask-border * -0.5, $sz--mask-border * -0.5);
            }
        }

        .process--video,
        .process--illustration {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
        }

        .process--illustration {
            z-index: 1;

            // NOTE: This may change when disabling the video for page weight or performance reasons
            display: none;
        }

        .process--video {
            z-index: 2;
            border-radius: 50%;
            overflow: hidden;
        }

        .process--title {
            @include type--copy-x-large;
        }

        .process--sub-title {
            @include type--copy-x-large;

            @include mq('<=sm') {
                @include type--copy-large;
            }
        }

        .process--text {
            @include type--copy-large;
            margin-top: 12px;
            color: $c--monochrome-gray;
        }

        .process--more {
            margin-top: 60px;
            text-align: center;

            @include mq('<=sm') {
                margin-top: 48px;
            }
        }
    }

    // Facets

    // States
}
