.v-date-calendar,
.v-date-range {
    // Vars
    $sz--date-height: 90%;
    $sz--arrow: 6px;

    // Support
    @function v-date-calendar--draw-circle($color) {
        @return radial-gradient(circle closest-side, $color, $color 95%, transparent 100%);
    }

    @function v-date-calendar--draw-rect($color, $side: null) {
        @if ($side == 'left' or $side == 'right') {
            $to-side: if($side == 'left', 'right', 'left');
            @return linear-gradient(to #{ $to-side }, $color, $color 49%, transparent 50%);
        }

        @return linear-gradient($color, $color);
    }

    @mixin v-date-calendar--cell-focus() {
        color: $c--label-text;

        &::after {
            background-image: v-date-calendar--draw-circle(rgba($c--brand-light-blue, 0.2));
        }
    }

    @mixin v-date-calendar--cell-active() {
        color: $c--monochrome-white;

        &::after {
            background-image: v-date-calendar--draw-circle($c--brand-blue);
        }
    }

    // Module
    & {
        & {
            height: 100% !important; // stylelint-disable-line
        }

        // Month selector
        .v-date-month-year {
            > .v-date-arrow-button,
            > .v-date-text {
                cursor: pointer;
            }

            .v-date-arrow {
                width: $sz--arrow;
                height: $sz--arrow;
                border-color: $c--brand-light-blue;
                transition: border-color $trs--base;
            }

            > .v-date-arrow-button {
                @include has-focus(true) {
                    .v-date-arrow {
                        border-color: $c--brand-dark-blue;
                    }
                }
            }
        }

        .v-date-cell {
            position: relative;
            cursor: pointer;

            &::before,
            &::after {
                content: '';
                pointer-events: none;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: $sz--date-height;
                transform: translate(-50%, -50%) scale(1.02, 1);
            }
        }

        .v-date-cell-text {
            @include type--copy-xx-small;
            z-index: 1;
        }

        .v-date-cell.v-date-start-day,
        .v-date-cell.v-date-end-day,
        .v-date-cell.v-date-selected,
        .v-date-cell.v-date-in-range {
            background: none;
        }

        .v-date-passive {
            cursor: default;
        }

        // Month and year cells
        .v-date-month-cell,
        .v-date-year-cell {
            // Focused
            @include has-focus(true) {
                @include v-date-calendar--cell-focus();
            }

            // Selected
            &.v-date-selected {
                @include has-focus(default) {
                    @include v-date-calendar--cell-active();
                }
            }
        }

        // Selectable day cells
        .v-date-day-cell {
            &:not(.v-date-start-day):not(.v-date-end-day) {
                // Today
                &.v-date-today {
                    color: $c--label-text;

                    &::after {
                        background-image: v-date-calendar--draw-circle($c--brand-salmon);
                    }
                }

                // Between `start-day` and `end-day`
                &.v-date-in-range {
                    &::before {
                        background-image: v-date-calendar--draw-rect($c--brand-light-blue);
                    }
                }

                // Selected day cells
                &.v-date-selected:not(.v-date-passive) {
                    @include v-date-calendar--cell-focus();
                }

                // Focused
                &:not(.v-date-passive) {
                    @include has-focus(true) {
                        @include v-date-calendar--cell-focus();
                    }
                }
            }

            // Draw a circle on `start-day` and `end-day`
            &.v-date-start-day, &.v-date-end-day {
                @include v-date-calendar--cell-active();
            }

            // Draw the right side of a `background` on the `start-day`
            &.v-date-start-day:not(.v-date-end-day) {
                &::before {
                    background-image: v-date-calendar--draw-rect($c--brand-light-blue, right);
                }
            }

            // Draw the left side of a `background` on the `end-day`
            &.v-date-end-day:not(.v-date-start-day) {
                &::before {
                    background-image: v-date-calendar--draw-rect($c--brand-light-blue, left);
                }
            }
        }
    }

    // Facets

    // States
}
