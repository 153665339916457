.accordion-item {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        background: $c--monochrome-white;

        // trick to render the border bottom
        // this border will be hidden by the next accordion item if it's present
        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            background: $c--monochrome-light-gray;
        }

        .accordion-item--head {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            border-top: 2px solid $c--monochrome-light-gray;
            transition: background-color $trs--base;
        }

        .accordion-item--icon {
            margin-left: 16px;
            color: $c--brand-light-blue;
            transition: transform $trs--base;
        }

        .accordion-item--body {
            transition: background-color $trs--base;
            padding: 16px;
        }
    }

    // Facets
    & {
        &.accordion-item__is-active {
            .accordion-item--head,
            .accordion-item--body {
                background-color: rgba($c--monochrome-light-gray, 0.5);
            }

            .accordion-item--icon {
                transform: rotateX(0.5turn);
            }
        }

        &.accordion-item__snippet {
            .accordion-item--body {
                padding-top: 0;
            }
        }
    }

    // States
}
