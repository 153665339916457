/// Secondary
///
@mixin btn--simple-text() {
    // Vars

    // Support

    // Module
    & {
        @include btn--all();
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--simple-text-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--simple-text-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--simple-text-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--simple-text-disabled();
        }
    }
}

/// Base
///
@mixin btn--simple-text-base() {
    color: $c--brand-light-blue;
    background-color: transparent;
}

/// Focus
///
@mixin btn--simple-text-focus() {
    color: mix($c--brand-light-blue, $c--brand-dark-blue, 40%);
}

/// Active
///
@mixin btn--simple-text-active() {
    color: mix($c--brand-light-blue, $c--brand-dark-blue, 0%);
}

/// Disabled
///
@mixin btn--simple-text-disabled() {
    color: $c--monochrome-silver;
}
