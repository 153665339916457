.form-card {
    // Vars
    $c--shadow: #77b2c0;
    $sz--form-width: $sz--confirm-modal-width;
    $sz--form-height: 320px;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        .form-card--content {
            width: 100%;
            max-width: $sz--form-width;
        }

        .form-card--header {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            margin-bottom: 48px;
        }

        .form-card--body {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            overflow: hidden;
            min-height: $sz--form-height;
            padding: 32px;
            background-color: $c--monochrome-white;
            box-shadow: 0 0 20px 0 $c--shadow;

            @include mq('<=sm') {
                padding: 16px;
            }
        }

        .form-card--footer {
            margin-top: 24px;
            text-align: center;
        }

        .form-card--intro {
            margin-bottom: 16px;
        }

        .form-card--title {
            display: block;
            width: 100%;
        }

        .form-card--description {
            @include type--copy-x-small();
            display: block;
            width: 100%;
            margin-top: 4px;
        }

        .form-card--footer-link {
            @include type--copy-xx-small();
            color: $c--monochrome-white;
            text-decoration: underline;
            transition: color $trs--base;

            @include has-focus(true) {
                color: mix($c--monochrome-white, $c--brand-light-blue, 80%);
            }
        }
    }

    // Facets
    & {
        &.form-card__download {
            .form-card--body {
                align-content: stretch;
            }

            .form-card--actions {
                overflow: hidden;
            }
        }

        &.form-card__modal {
            .form-card--body {
                border-radius: $sz--border-radius-base;
                box-shadow: none;
            }
        }
    }

    // States
}
