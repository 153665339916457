/// Field styles
///
@mixin form-field--cui-body-regions() {
    // Vars

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-self: flex-end;

        @include mq('>md') {
            width: 408px;
        }

        @include mq(('<=md' '>xs')) {
            width: 300px;
        }

        @include mq('xs') {
            width: calc(100% - 44px);
        }

        .form-field--bubble-wrap {
            position: relative;
            display: inline-flex;
            margin-bottom: $sz--border-width-base;

            @include at-root(html, '.ie11') {
                width: 100%;
            }
        }

        .form-field--bubble {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $c--monochrome-white;
            border-radius: $sz--border-radius-cui;

            border-top-right-radius: 0;
            box-shadow: 0 $sz--border-width-base 0 0 $c--brand-blue;
        }

        .form-field--bubble-content-outer {
            display: flex;
            flex-flow: row nowrap;
            padding: ($sz--bubble-padding-y + 1px) $sz--bubble-padding-x ($sz--bubble-padding-y - 1px);

            @include at-root(html, '.ie11') {
                width: 100%;
            }
        }

        .form-field--bubble-content-inner {
            @include grid--span((ratio: 1/2));
            z-index: 1;
            position: relative;
            height: $sz--body-regions-display-height;

            &:nth-child(1) {
                padding-right: 4px;
            }

            &:nth-child(2) {
                padding-left: 4px;
            }
        }

        .form-field--bubble-content {
            display: block;
            height: 100%;
            cursor: pointer;

            @include at-root(html, '.ie11') {
                width: 100%;
            }
        }
    }

    // States
}
