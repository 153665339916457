.news-list {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin('.news-list--bg');

        .news-list--bg {
            //
        }

        .news-list--container.news-list--container {
            overflow: visible;
        }

        .news-list--title {
            @include grid--span((ratio: 3/4, mq: '>sm'));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
        }

        .news-list--more {
            @include grid--span((ratio: 1/4, mq: '>sm'));
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            justify-content: flex-end;

            @include mq('<=sm') {
                display: none;
            }
        }

        .news-list--detail-hint.news-list--detail-hint__top {
            margin-bottom: 32px;
        }

        .news-list--detail-hint.news-list--detail-hint__bottom {
            margin-top: 24px;

            @include mq('>sm') {
                display: none;
            }
        }
    }

    // Facets
    & {
        &.news-list__base {
            color: $c--brand-light-blue;

            .news-list--bg {
                background-color: $c--monochrome-white;
            }
        }

        &.news-list__blue {
            color: $c--monochrome-white;

            .news-list--bg {
                @include bg--cui();
            }
        }
    }

    // States
}
