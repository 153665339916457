.cui-message-display-content-readonly {
    // Vars

    // Support

    // Module
    & {
        padding: ($sz--bubble-padding-y + 1px) $sz--bubble-padding-x ($sz--bubble-padding-y - 1px);

        .cui-message-display-content-readonly--title,
        .cui-message-display-content-readonly--text {
            display: block;
            text-align: left;
            min-width: 24px;
        }

        .cui-message-display-content-readonly--text {
            @include type--copy-small;
        }
    }

    // Facets

    // States
}
