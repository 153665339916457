/// Secondary
///
@mixin btn--secondary() {
    // Vars

    // Support

    // Module
    & {
        @include btn--all();
    }

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(()) {
            @include btn--secondary-base();
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--secondary-focus();
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--secondary-active();
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--secondary-disabled();
        }
    }
}

/// Base
///
@mixin btn--secondary-base() {
    color: $c--brand-light-blue;
    background-color: transparent;
    border-color: $c--brand-light-blue;
}

/// Focus
///
@mixin btn--secondary-focus() {
    background-color: rgba($c--brand-light-blue, 0.1);
}

/// Active
///
@mixin btn--secondary-active() {
    background-color: rgba($c--brand-light-blue, 0.2);
}

/// Disabled
///
@mixin btn--secondary-disabled() {
    color: $c--monochrome-silver;
    border-color: $c--monochrome-silver;
}
