// General spacing
$sz--border-radius-base: 3px;
$sz--border-width-base: 2px;
$sz--border-radius-cui: 12px;
$sz--border-radius-cui-input: 2px;

// plus
$sz--plus-svg: 12px;
$sz--plus-pattern-unitless: 30;

// main-nav
$sz--main-nav-height: 80px;

// mobile-nav
$sz--mobile-nav-height: 68px;

// cui-progress
$sz--cui-progress-height: 50px;

// cui-nav
$sz--cui-nav-height: 28px;

// cui-manager
$sz--chat-spacing: 12px;

// cui-message
$sz--bubble-initial-size: 54px;
$sz--bubble-padding-x: 16px;
$sz--bubble-padding-y: 14px;

// benefits / process
$sz--illustration: 250px;

// confirm-modal
$sz--confirm-modal-width: 374px;

// info-modal
$sz--info-modal-width: 440px;

// snippets-modal
$sz--snippets-modal-width: 654px;

// voucher-modal
$sz--voucher-modal-width: 470px;

// page-dashboard
$sz--dashboard-header-height: $sz--main-nav-height;
$sz--dashboard-sidebar-width: 280px;

// body-regions-display
$sz--body-regions-display-width: 376px;
$sz--body-regions-display-height: 250px;
