.personal-data-form {
    // Vars

    // Support

    // Module
    & {
        background-color: $c--monochrome-white;

        .personal-data-form--title {
            color: $c--brand-light-blue;
        }

        .personal-data-form--separator {
            color: $c--brand-light-blue;
            margin: 12px 0 54px;
        }

        .personal-data-form--row {
            justify-content: center;
        }

        .personal-data-form--col {
            @include grid--span((ratio: 4/12, mq: '>lg'));
            @include grid--span((ratio: 5/12, mq: 'lg'));
            @include grid--span((ratio: 6/12, mq: 'md'));
            @include grid--span((ratio: 8/12, mq: 'sm'));
            @include grid--span((ratio: 1/1, mq: 'xs'));
        }

        .personal-data-form--content {
            padding: (60px + $sz--cui-progress-height) 0 60px;
        }
    }

    // Facets

    // States
}
