/// Field styles
///
@mixin form-field--primary--floating-label($sz--padding-top, $sz--padding-x, $sz--padding-bottom) {
    // Vars
    $fz--form-field: $fz--small;
    $lh--form-field: 1.38;

    // Support

    // Module
    & {
        transform: rotateZ(1turn); // NOTE: Prevent jitter when animating floating-label

        .form-field--input,
        .form-field--floating-label {
            padding-top: $sz--padding-top;
            padding-bottom: $sz--padding-bottom;
            padding-right: 2px;
        }

        .form-field--floating-label {
            @include text-ellipsis;
            pointer-events: none;
            position: absolute;
            font-size: $fz--form-field;
            line-height: $lh--form-field;
            top: 0;
            left: $sz--padding-x;
            visibility: visible;
            backface-visibility: hidden;
            transform-origin: 0 50%;
            transition: transform $trs--base, color $trs--base;
        }

        .form-field--title-label {
            @include hide-visually;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary--floating-label--base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary--floating-label-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary--floating-label--disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--primary--floating-label--error();
        }

        // Required
        @include form-field--apply-states(('required': true)) {
            @include form-field--primary--floating-label--required();
        }

        // Floating
        @include form-field--apply-states(('floating': true)) {
            @include form-field--primary--floating-label--floating();
        }
    }
}

/// Base
///
@mixin form-field--primary--floating-label--base() {
    .form-field--floating-label {
        color: $c--monochrome-gray;
    }
}

/// Focus
///
@mixin form-field--primary--floating-label-focus() {
    .form-field--floating-label {
        color: $c--brand-blue;
    }
}

/// Disabled
///
@mixin form-field--primary--floating-label--disabled() {
    .form-field--floating-label {
        color: $c--monochrome-silver;
    }
}

/// Error
///
@mixin form-field--primary--floating-label--error() {
    .form-field--floating-label {
        color: $c--signal-error;
    }
}

/// Required
///
@mixin form-field--primary--floating-label--required() {
    //
}

/// Floating
///
@mixin form-field--primary--floating-label--floating() {
    .form-field--floating-label {
        transform: translate3d(0, -40%, 0) scale(0.75);
    }
}
