.testimonial-list {
    // Vars

    // Support

    // Module
    & {
        color: $c--brand-light-blue;

        .testimonial-list--bg {
            @include bg--testimonial();
            @include bg--pattern();
            padding: 60px 0;
        }
    }

    // Facets

    // States
}
