/// Field styles
///
@mixin form-field--secondary-search() {
    // Vars

    // Module
    & {
        .form-field--input {
            @include ff--base-regular;
            font-size: 1.8rem;
            line-height: 1.55;
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--secondary-search-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--secondary-search-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--secondary-search-disabled();
        }

        // Empty
        @include form-field--apply-states(('filled': false)) {
            @include form-field--secondary-search-empty();
        }

        // Empty and disabled
        @include form-field--apply-states(('filled': false, 'disabled': true)) {
            @include form-field--secondary-search-empty-disabled();
        }
    }
}

/// Base
///
@mixin form-field--secondary-search-base() {
    & {
        width: 50%;
        padding-right: 18px;

        @include mq('<=md') {
            width: 100%;
            padding-right: 0;
        }
    }

    .form-field--input-container::after {
        border-bottom-right-radius: 3px;
        max-width: calc(100% - 2px);
    }

    .form-field--input {
        background-color: $c--monochrome-white;
        padding: 10px 16px;
        border-radius: 2px 3px 3px 2px;
    }

    .form-field--action {
        width: unset;
        padding: 12px;

        @include has-focus(true) {
            color: $c--brand-dark-blue;
        }

        &:last-child {
            border-radius: 0 3px 3px 0;
        }

        &.form-field--action__search {
            color: $c--monochrome-white;
            background-color: $c--brand-blue;
        }
    }


    .form-field--action.form-field--action__toggle {
        color: $c--monochrome-light-gray;

        @include has-focus(true) {
            color: $c--brand-dark-blue;
        }
    }

    .form-field--action.form-field--action__loader {
        width: 48px;
        height: 48px;
        padding: 0;
    }
}

/// Focus
///
@mixin form-field--secondary-search-focus() {
    .form-field--action.form-field--action__toggle {
        color: $c--brand-light-blue;
    }
}

/// Disabled
///
@mixin form-field--secondary-search-disabled() {
    .form-field--action.form-field--action__toggle {
        display: none;
    }
}

/// Empty
///
@mixin form-field--secondary-search-empty() {
    .form-field--input {
        color: $c--monochrome-silver;
    }
}

/// Empty and disabled
///
@mixin form-field--secondary-search-empty-disabled() {
    .form-field--input {
        color: transparent;
    }
}
