.documedis-modal {
    // Vars
    $sz--bottom-bar-height-large: 100px;
    $sz--bottom-bar-height-small: 84px;

    $sz--logo-x: 124px;
    $sz--logo-y: 48px;

    $sz--logo-img-x: 100px;
    $sz--logo-img-y: 32px;

    // Support

    // Module
    & {
        @include grid--root((row: 'lists', cols: ('list', 'intro')));
        @include grid--space((gutter: (48px, 24px), row: 'lists', cols: ('list', 'intro')));

        position: relative;
        flex: 0 1 100%;
        width: 100%;
        background-color: $c--monochrome-white;

        @include mq('<=sm') {
            padding-bottom: $sz--bottom-bar-height-small;
        }

        .header-box {
            background-color: #479EB2;
            display: flex;
            height: 80px;
            align-items: center;
            position: relative;

             @include mq('<=sm') {
                 height: 59px;

            }
        }

        .header-logo {
            fill: white;
            width: $sz--logo-x;
            height: $sz--logo-y;
            margin-left: 25px;

             @include mq('<=sm') {
                 width: $sz--logo-img-x;
                 height: $sz--logo-img-y;
                 margin-left: 13px;
            }
        }
        .header-close-button {
            display: flex;
            justify-content: flex-end;
            flex: 3;
        }
        .header-close-button-icon {
            fill: white;
        }

        .header-text {
             font-size: 18px;
             color:white;
             font-weight: 600;
             margin-left: 40px;

             @include mq('<=sm') {
                 font-size: 12px;
                 margin-left: 20px;

            }
        }

        .dummy-iframe-toggle-button {
            background-color: #FFCC99;
            border: 2px solid #FF8000;
            color: #FF8000;
            padding: 10px 20px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.3s ease;
            position: fixed;
            bottom: 10px;
            left: 10px;
            border-radius: 25px;
            box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
        }

        .dummy-iframe-toggle-button:hover {
            background-color: #FF8000;
            color: #FFFFFF;
        }
    }

    // Facets

    // States
}
