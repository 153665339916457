.new-feature-info {
    // Module
    margin-bottom: 2px;
    & {
        .new-feature-info-box {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 25px;
            background: #ffb81c;
            display: flex;
            padding-right: 16px;
            padding-left: 16px;
            padding-top: 2px;
            padding-bottom: 2px;
            margin-top: -5px;

            .new-feature-info-text {
                font-size: $fz--small;
                padding-right: 8px;
            }

            .new-feature-info-icon {
                margin-top: 3px;
            }
        }
    }
}
