.waiting-modal {
    // Vars

    // Support

    // Module
    & {
        .waiting-modal--wrapper {
            position: relative;
            width: 100%;
            padding-top: 40px;
            color: $c--brand-light-blue;
        }
    }

    // Facets

    // States
}
