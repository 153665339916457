.confirm-modal {
    // Vars

    // Support

    // Module
    & {
        .confirm-modal--container.confirm-modal--container {
            max-width: $sz--confirm-modal-width;

            @include at-root(html, '.ie11') {
                box-sizing: content-box;
                max-width: calc(#{$sz--confirm-modal-width} - 0.1px);

                // stylelint-disable selector-max-universal
                > * {
                    box-sizing: border-box;

                    * {
                        box-sizing: inherit;
                    }
                }
                // stylelint-enable selector-max-universal
            }
        }

        .confirm-modal--header {
            padding: 14px 16px;
            background-color: $c--monochrome-white;
            border-top-left-radius: $sz--border-radius-base;
            border-top-right-radius: $sz--border-radius-base;
            border-bottom: 1px solid $c--monochrome-light-gray;
            overflow: hidden;

            @include at-root(html, '.edge') {
                border-radius: 0;
            }
        }

        .confirm-modal--body {
            min-height: 90px;
            padding: 14px 16px;
            background-color: $c--monochrome-white;
            text-align: center;
            overflow: hidden;

            &:last-child {
                border-bottom-left-radius: $sz--border-radius-base;
                border-bottom-right-radius: $sz--border-radius-base;

                @include at-root(html, '.edge') {
                    border-radius: 0;
                }
            }
        }

        .confirm-modal--title {
            color: $c--brand-light-blue;
            text-align: center;
        }

        .confirm-modal--description {
            @include type--copy;
            text-align: left;
        }

        .confirm-modal--footer {
            $sz--bg-offset: ($sz--border-width-base * 2);

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            background-image: linear-gradient(
                to top,
                transparent 0,
                transparent $sz--bg-offset,
                $c--monochrome-white ($sz--bg-offset + 1px),
                $c--monochrome-white 100%
            );
            border-top: 1px solid $c--brand-light-blue;
        }

        .confirm-modal--btn {
            flex: 0 1 50%;
            overflow: hidden;
            box-shadow: none;

            &:first-child,
            &:last-child {
                &::before {
                    content: none;
                }

                &, &::before, &::after {
                    border-radius: 0;
                }
            }

            &:only-child {
                flex: 0 1 100%;
            }

            &:first-child {
                &, &::before, &::after {
                    border-bottom-left-radius: $sz--border-radius-base;

                    @include at-root(html, '.edge') {
                        border-radius: 0;
                    }
                }
            }

            &:last-child {
                &, &::before, &::after {
                    border-bottom-right-radius: $sz--border-radius-base;

                    @include at-root(html, '.edge') {
                        border-radius: 0;
                    }
                }
            }
        }
    }

    // Facets

    // States
}
