.team-wrapper {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin();

        .team-wrapper--title {
            color: $c--brand-light-blue;
        }

        .team-wrapper--separator {
            display: block;
            margin: 12px 0 34px;
            color: $c--brand-light-blue;
        }

        .team-wrapper--item {
            @include grid--span((ratio: 1/1));
            overflow: hidden;
        }
    }

    // Facets

    // States
}

