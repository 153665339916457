.doctor-list {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin();

        .doctor-list--intro {
            color: $c--brand-light-blue;
        }

        .doctor-list--separator {
            display: block;
            margin: 12px 0 36px;
        }

        .doctor-list--filter {
            display: flex;
            margin-bottom: 40px;
        }

        .doctor-list--more {
            margin-top: 60px;
            text-align: center;

            @include mq('<=sm') {
                margin-top: 48px;
            }
        }
    }

    .doctor-list--empty-message {
        @include type--h1;
        color: $c--monochrome-white;
        margin-bottom: 80px;

        @include mq('<=md') {
            margin-bottom: 40px;
        }
    }

    .doctor-list--recomendations-title {
        @include type--h3;
        @include ff--base-regular;
        font-weight: $fw--normal;

        color: $c--monochrome-white;
        margin-bottom: 22px;
    }

    .doctor-list--recomendations-button {
        & + .doctor-list--recomendations-button {
            margin-left: 24px;
        }
    }

    // Facets
    & {
        // Home
        &.doctor-list__home {
            @each $value in $grid--base-container-sizes {
                @include grid--constrain($value);
            }
        }

        // Doctors
        &.doctor-list__doctors {
            padding-top: 30px;

            @include mq('<=md') {
                padding-top: 30px;
            }

            .doctor-list--more {
                margin-top: 40px;
                text-align: left;

                @include mq('<=sm') {
                    margin-top: 24px;
                }
            }
        }

        // Landing page
        &.doctor-list__landingpage {
            .doctor-list--intro {
                margin-bottom: 20px;
                color: $c--monochrome-dark-gray;
            }

            .doctor-list--separator {
                display: none;
            }

            .doctor-list--more {
                margin-top: 24px;
            }
        }
    }

    // States
}
