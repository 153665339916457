.body-regions-display {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        height: 100%;

        .body-regions-display--positioner {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
        }

        .body-regions-display--label {
            @include type--copy-xx-small;
            @include ff--base-semi-bold;
            margin-top: 12px;
            color: $c--brand-dark-blue;
        }
    }

    // Facets
    & {
        // In overlay
        &.body-regions-display__in-overlay {
            .body-regions-display--positioner {
                @include overlay();
                padding: 12px;
                transition: transform 400ms ease(out-material);
            }
        }

        // In CUI
        &.body-regions-display__in-cui {
            width: 100%;

            .body-regions-display--positioner {
                display: flex;
                flex-flow: column nowrap;
                width: 100%;
                height: 100%;
            }
        }

        // In dahsboard
        &.body-regions-display__in-dashboard {
            display: flex;

            .body-regions-display--positioner {
                width: 100%;

                @include mq('<=md') {
                    justify-content: center;
                    width: auto;
                }
            }

            .body-regions-display--element {
                /* stylelint-disable */
                // fix a chrome bug on Windows introduced with Chrome 84
                // probably this patch will be no longer needed in future
                @include mq('>lg') {
                    max-height: 356px !important;
                }

                @include mq('<=lg') {
                    max-height: 266px !important;
                }
                /* stylelint-enable */
            }
        }

        // In preview modal
        &.body-regions-display__in-preview-modal {
            .body-regions-display--positioner {
                width: 100%;
                height: 100%;
            }
        }
    }

    // States
}
