.form-field-erx {
    // Vars

    // Support

    // Module
    & {
        font-size: 1.2rem;
        line-height: 1.25;
        margin-top: 4px;
        color: #bebfc1;
        margin-top: 11px;

        @media (max-width: 375px) {
            text-align: center;
        }
    }

    // Facets

    // States
}
