.map {
    // Vars
    $sz--map-ratio-large: (100% * 1 / 3);
    $sz--map-ratio-small: (100% * 10 / 8);

    // Support

    // Module
    & {
        .map--wrap {
            position: relative;
            padding-top: $sz--map-ratio-large;
            overflow: hidden;

            @include mq('<=#{$bp--mobile}') {
                padding-top: $sz--map-ratio-small;
            }
        }

        .map--root {
            @include overlay();
        }

        .map--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .map--overlay-box {
            pointer-events: none;
            position: absolute;
            padding: 36px;

            @include mq('>#{$bp--mobile}') {
                max-width: 40%;
            }

            @include mq('<=#{$bp--mobile}') {
                width: 100%;
                padding: 16px;
            }
        }

        .map--overlay-content {
            pointer-events: auto;
            display: inline-flex;
            padding: 14px 20px 14px 12px;
            background-color: $c--monochrome-white;
            box-shadow: 0 0 20px 0 rgba($c--monochrome-dark-gray, 0.25);
        }

        .map--overlay-icon {
            display: inline-block;
        }

        .map--overlay-icon {
            margin-top: 0; // NOTE: To take into account the line-height
            margin-right: 4px;
            transition: color $trs--base;
            color: $c--brand-light-blue;
        }

        .map--overlay-text {
            font-size: $fz--xx-small;
            line-height: 1.8rem;
        }
    }

    // Facets

    // States
}
