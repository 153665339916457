/// Field styles
///
@mixin form-field--cui-bool($fz--label, $lh--label, $sz--box, $sz--box-border) {
    // Vars
    $sz--label-gutter: 8px;

    $sz--list-gutter-x: 8px;
    $sz--list-gutter-y: 8px;

    $sz--shadow: 2px;

    // Module
    & {
        @include grid--root((cols: ('input-container')));
        @include grid--space((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), cols: ('input-container')));
        font-size: $fz--label;
        line-height: 0;

        .form-field--row {
            justify-content: flex-end;
        }

        .form-field--input {
            @include hide-visually;
        }

        .form-field--input-container {
            z-index: 0;
            display: flex;
            flex: 0 0 auto;
        }

        .form-field--title-label {
            cursor: default;
        }

        .form-field--label-wrap {
            z-index: 1;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-height: none;
            padding: 11px 12px 10px;
            margin: $sz--shadow 0 $sz--shadow 0;
            transform: translate(0, -$sz--shadow);
            backface-visibility: hidden;
            border-radius: $sz--border-radius-base;
            transition: $trs--common-props;
            cursor: pointer;

            &::after,
            &::before {
                @include btn--base();
                content: '';
                z-index: -1;
                position: absolute;
                top: $sz--shadow;
                left: $sz--shadow;
                display: block;
                width: calc(100% - #{$sz--shadow * 2});
                height: calc(100% - #{$sz--shadow * 2});
                box-sizing: content-box;
                border-width: $sz--box-border;

                transition: transition-props($trs--base, color, border-color, background-color, opacity, transform);
            }
        }

        .form-field--label {
            @include ff--base-semi-bold;
            flex: 1 0 auto;
            padding: 0 0 0 $sz--label-gutter;
            transform: translateY(0);
        }

        .form-field--box {
            @include is-selectable(false);
            position: relative;
            display: block;
            flex: 0 0 auto;
            width: $sz--box;
            height: $sz--box;
            padding: $sz--box-border;
            background-clip: content-box;
            transition: $trs--common-props;

            @include at-root(html, '.ie11') {
                background-clip: border-box;
                width: $sz--box - 2px;
                height: $sz--box - 2px;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                transition: $trs--common-props;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: 2;
                border-width: $sz--box-border;
                border-style: solid;
            }
        }

        .form-field--box-icon {
            pointer-events: none;
            z-index: 3;
            opacity: 0;
            transition: $trs--common-props;
        }
    }

    // Facets
    & {
        &.form-field__vertical {
            .form-field--row {
                flex-flow: column nowrap;
            }
        }

        &.form-field__multiline {
            .form-field--label-wrap {
                max-height: none;
            }

            .form-field--label {
                flex: 0 1 auto;
                line-height: $lh--label;
            }
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            .form-field--title-label {
                color: $c--label-text;
            }

            .form-field--input {
                // Base
                @include form-field--bool-apply-states(()) {
                    @include form-field--cui-bool-base(
                        $sz--shadow: $sz--shadow,
                        $sz--box-border: $sz--box-border
                    );
                }

                // Hover
                @include form-field--bool-apply-states(('disabled': false, 'hover': true)) {
                    @include form-field--cui-bool-hover();
                }

                // Focus
                @include form-field--bool-apply-states(('disabled': false, 'focus': true)) {
                    @include form-field--cui-bool-focus();
                }

                // Checked
                @include form-field--bool-apply-states(('checked': true, 'hover': default)) {
                    @include form-field--cui-bool-checked(
                        $sz--box-border: $sz--box-border
                    );
                }

                // Checked and focus
                @include form-field--bool-apply-states(('checked': true, 'hover': default, 'focus': true)) {
                    @include form-field--cui-bool-focus-checked();
                }

                // Disabled
                @include form-field--bool-apply-states(('disabled': true)) {
                    @include form-field--cui-bool-disabled();
                }

                // Disabled and checked
                @include form-field--bool-apply-states(('disabled': true, 'checked': true)) {
                    @include form-field--cui-bool-disabled-checked();
                }
            }
        }

        // Error
        @include form-field--apply-states(('error': true)) {
            .form-field--title-label {
                color: $c--signal-error;
            }

            .form-field--input {
                // Base
                @include form-field--bool-apply-states(()) {
                    @include form-field--cui-bool-error();
                }

                // Hover
                @include form-field--bool-apply-states(('disabled': false, 'hover': true)) {
                    //
                }

                // Focus
                @include form-field--bool-apply-states(('disabled': false, 'focus': true)) {
                    //
                }

                // Checked
                @include form-field--bool-apply-states(('checked': true, 'hover': default)) {
                    @include form-field--cui-bool-error();
                }

                // Checked and focus
                @include form-field--bool-apply-states(('checked': true, 'hover': default, 'focus': true)) {
                    @include form-field--cui-bool-focus-checked();
                }

                // Disabled
                @include form-field--bool-apply-states(('disabled': true)) {
                    @include form-field--cui-bool-disabled();
                }

                // Disabled and checked
                @include form-field--bool-apply-states(('disabled': true, 'checked': true)) {
                    @include form-field--cui-bool-disabled-checked();
                }
            }
        }
    }
}

/// Base
///
@mixin form-field--cui-bool-base($sz--shadow, $sz--box-border) {
    color: $c--monochrome-white;

    &.form-field--label-wrap {
        &::after {
            background-color: $c--brand-blue;
            transform: translate(-$sz--box-border, -$sz--box-border);
        }

        &::before {
            background-color: $c--brand-dark-blue;
            transform: translate(-$sz--box-border, -$sz--box-border + $sz--shadow);
        }
    }

    .form-field--box::before {
        opacity: 0;
    }

    .form-field--box {
        background-color: $c--monochrome-white;
    }

    .form-field--box::after {
        border-color: transparent;
    }

    .form-field--box-icon {
        color: transparent;
    }
}

/// Hover
///
@mixin form-field--cui-bool-hover() {
    &.form-field--label-wrap {
        &::after {
            background-color: color-tint($c--brand-blue, 10%);
        }
    }

    .form-field--box-icon {
        opacity: 0.25;
    }
}

/// Focus
///
@mixin form-field--cui-bool-focus() {
    &.form-field--label-wrap {
        &::after {
            background-color: color-tint($c--brand-blue, 10%);
        }
    }

    .form-field--box::before {
        opacity: 1;
    }

    .form-field--box::after {
        border-color: transparent;
    }
}

/// Checked
///
@mixin form-field--cui-bool-checked($sz--box-border) {
    &.form-field--label-wrap {
        transform: translate(0);

        &::before, &::after {
            background-color: color-shade($c--brand-blue, 20%);
            transform: translate(-$sz--box-border, -$sz--box-border);
        }
    }

    .form-field--box::after {
        border-color: transparent;
    }

    .form-field--box-icon {
        opacity: 1;
        color: $c--brand-blue;
    }
}

/// Focus and checked
///
@mixin form-field--cui-bool-focus-checked() {
    &.form-field--label-wrap {
        &::before, &::after {
            background-color: color-shade($c--brand-blue, 30%);
        }
    }
}

/// Error
///
@mixin form-field--cui-bool-error() {
    .form-field--box {
        background-color: color-tint($c--signal-error, 80%);
    }
}

/// Disabled
///
@mixin form-field--cui-bool-disabled() {
    cursor: default;

    &.form-field--label-wrap {
        &, &::after, &::before {
            cursor: default;
        }
    }

    .form-field--label {
        cursor: auto;
    }

    .form-field--box {
        background-color: $c--monochrome-light-gray;
    }

    .form-field--box-icon {
        color: $c--monochrome-silver;
    }
}

/// Disabled and checked
///
@mixin form-field--cui-bool-disabled-checked() {
    .form-field--box-icon {
        color: $c--monochrome-silver;
    }
}
