.cui-message-editor {
    // Vars

    // Support
    @mixin cui-message-editor--target-field($append-selector: null) {
        $append-modifier: if($append-selector, '.form-field__#{ $append-selector }', '.form-field');

        .form-field#{ $append-modifier } + .cui-message-editor--proceed {
            @content;
        }
    }

    // Module
    & {
        width: 100%;

        .cui-message-editor--wrap {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            width: 100%;
        }

        .cui-message-editor--proceed {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
        }
    }

    // Facets
    & {
        &.cui-message-editor__input {
            .cui-message-editor--proceed {
                position: absolute;
                top: 2px;
                right: 0;
                margin: 6px;
            }

            @include cui-message-editor--target-field() {
                .btn {
                    color: $c--brand-blue;
                }
            }

            @include cui-message-editor--target-field('has-focus') {
                .btn:not(:disabled) {
                    background-color: $c--monochrome-light-gray;
                }
            }

            @include cui-message-editor--target-field('is-filled') {
                .btn:not(:disabled) {
                    @include has-focus(false) {
                        color: $c--monochrome-white;
                        background-color: $c--brand-blue;
                    }

                    @include has-focus(true) {
                        color: $c--monochrome-white;
                        background-color: color-shade($c--brand-blue, 20%);
                    }
                }
            }
        }
    }

    // States
}
