.partner-list {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin('.partner-list--bg');
        color: $c--brand-light-blue;

        .partner-list--bg {
            //
        }

        .partners--more {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            margin-top: 16px;
        }

        .partners--more {
            width: 100%;
        }

        .partners--more-button {
            margin-top: 44px;
        }

        .partners--accordion {
            width: 100%;
        }
    }

    // Facets

    // States
}
