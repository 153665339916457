.jumbotron {
    // Vars
    $sz--mobile-bottom-offset: 300px;

    // Support

    // Module
    & {
        position: relative;

        .jumbotron--wrapper {
            position: relative;

            @include mq('>#{$bp--mobile}') {
                min-height: 500px;
                padding-top: calc(100vh - #{ $sz--main-nav-height });
            }
        }

        .jumbotron--bg {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;

            @include mq('>#{$bp--mobile}') {
                height: 100%;
                background-position: center;
            }

            @include mq('<=#{$bp--mobile}') {
                height: $sz--mobile-bottom-offset;
                background-position: right center;
            }
        }

        .jumbotron--content {
            z-index: 1;
            color: $c--monochrome-white;

            &::after {
                @include overlay();
                content: '';
                display: block;
            }

            @include mq('<=#{$bp--mobile}') {
                position: relative;
                padding: 60px 0 $sz--mobile-bottom-offset;

                &::after {
                    background-image: linear-gradient(to top, rgba($c--brand-light-blue, 0), $c--brand-light-blue $sz--mobile-bottom-offset, $c--brand-light-blue);
                }
            }

            @include mq('>#{$bp--mobile}') {
                @include overlay();

                &::after {
                    @include overlay();
                    background-image: linear-gradient(to right, $c--brand-light-blue, $c--brand-light-blue 25%, rgba($c--brand-light-blue, 0) 70%, rgba($c--brand-light-blue, 0));
                }
            }
        }

        .jumbotron--container {
            z-index: 1;
            position: relative;
            justify-content: center;
            height: 100%;
        }

        .jumbotron--col {
            @include grid--span((ratio: 1/3, mq: '>lg'));
            @include grid--span((ratio: 1/2, mq: ('md', 'lg')));
            @include grid--span((ratio: 1/1, mq: '<=sm'));
            overflow: hidden;
        }

        .jumbotron--separator {
            display: block;
            margin: 12px 0 16px;
        }

        .jumbotron--lead {
            @include type--copy;
            display: inline-block;
        }

        .jumbotron--cta {
            margin-top: 24px;
        }

        .jumbotron--scroller {
            z-index: 1;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            padding-bottom: 32px;

            @include mq('>#{$bp--mobile}') {
                position: fixed;
            }

            @include mq('<=#{$bp--mobile}') {
                position: absolute;
            }
        }
    }

    // Facets

    // States
}
