.cui-progress {
    // Vars
    $sz--btn-width: 28px;

    // Support

    // Module
    & {
        z-index: z-index('cui-progress');
        position: fixed;
        left: 0;
        width: 100%;

        @include mq('>#{$bp--mobile}') {
            top: $sz--main-nav-height;
        }

        @include mq('<=#{$bp--mobile}') {
            top: $sz--mobile-nav-height;
        }

        .cui-progress--wrapper {
            position: relative;
            background-color: $c--brand-dark-blue;
            transition: transform $trs--base;
        }

        .cui-progress--steps {
            z-index: 2;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            transition: opacity $trs--base;
        }

        .cui-progress--step {
            @include type--copy-small;
            @include grid--span((ratio: 1/3));
            display: flex;
            justify-content: center;
            align-items: center;
            height: $sz--cui-progress-height;
            overflow: hidden;
            color: $c--monochrome-white;

            ~ .cui-progress--step {
                border-left: 1px solid $c--brand-blue;
            }
        }

        .cui-progress--indicator {
            @include overlay();
            pointer-events: none;
            z-index: 1;
            background-color: $c--brand-light-blue;
            transform: scaleX(0);
            transform-origin: top left;

            transition: transform $trs--cui-progress-indicator;
        }

        .cui-progress--flap {
            pointer-events: none;
            position: absolute;
            top: 100%;
            width: 100%;
            background-color: $c--brand-dark-blue;
            transform: translateY(-1px);
        }

        .cui-progress--flap-content {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
        }

        .cui-progress--flap-indicator-wrap {
            position: absolute;
            top: 0;
            left: $sz--btn-width / 2;
            width: 100vw;
            height: 100%;
            background-color: $c--brand-dark-blue;
        }

        .cui-progress--flap-btn {
            pointer-events: auto;
            z-index: 1;
            position: relative;
            display: block;
            width: $sz--btn-width;
            height: 24px;
            color: $c--monochrome-white;
            cursor: pointer;
        }

        .cui-progress--flap-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            transition: transform $trs--base 200ms;
        }

        .cui-progress--flap-icon {
            display: block;
        }
    }

    // Facets

    // States
    & {
        & {
            .cui-progress--wrapper {
                transform: translateY(-100%) translateY(8px);
            }

            .cui-progress--steps {
                opacity: 0;
            }

            .cui-progress--flap-wrap {
                transform: none;
            }
        }

        &.cui-progress__is-active {
            .cui-progress--wrapper {
                transform: none;
            }

            .cui-progress--steps {
                opacity: 1;
            }

            .cui-progress--flap-wrap {
                transform: rotateX(0.5turn);
            }
        }
    }
}
