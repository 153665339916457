/* stylelint-disable selector-max-universal */


.rich-text {
    // Vars

    // Support

    // Module
    & {
        @include cms--padded-plugin();

        .rich-text--col {
            @include grid--span((ratio: 3/4, mq: '>lg'));
            @include grid--span((ratio: 1, mq: '<=lg'));
        }

        h3 {
            color: $c--brand-light-blue;
        }

        * ~ h3 {
            margin-top: 60px;
        }

        * ~ p,
        * ~ ul,
        * ~ ol {
            margin-top: 20px;
        }
    }

    // Facets

    // States
}

