.faq-entry {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .faq-entry--anchor {
            position: absolute;
            left: 0;

            @include mq('>#{$bp--mobile}') {
                top: ($sz--main-nav-height + 16px) * -1;
            }

            @include mq('<=#{$bp--mobile}') {
                top: ($sz--mobile-nav-height + 16px) * -1;
            }
        }

        .faq-entry--content {
            transition: background-color $trs--base;
        }

        .faq-entry--head {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
        }

        .faq-entry--question {
            @include type--copy;
            position: relative;
            transform: translateY(1px); // NOTE: Cosmetic correction
        }

        .faq-entry--icon {
            margin-left: 16px;
            color: $c--brand-light-blue;
            transition: transform $trs--base;
        }

        .faq-entry--response {
            @include type--copy-small;
            padding: 0 16px 16px;
            color: $c--monochrome-gray;
        }
    }

    // Facets
    & {
        &.faq-entry__is-active {
            .faq-entry--content {
                background-color: rgba($c--monochrome-light-gray, 0.5);
            }

            .faq-entry--icon {
                transform: rotateX(0.5turn);
            }
        }
    }

    // States
}
