.title {
    // Vars

    // Support
    @mixin title--column-line($num-columns) {
        @include mq('>sm') {
            @include column-separator($num-columns);
        }
    }

    // Module
    & {
        .title--prefix {
            @include type--copy-small;
            margin-bottom: 6px;
        }

        .title--separator {
            display: block;
            margin: 12px 0 16px;
        }

        .title--lead {
            overflow: hidden;
        }

        .title--lead-row {
            position: relative;
        }
    }

    // Facets
    & {
        &.title__num-cols-1 {
            .title--lead-col {
                @include grid--span((ratio: 3/4, mq: '>lg'));
                @include grid--span((ratio: 1, mq: '<=lg'));
            }
        }

        @for $num-cols from 2 through 3 {
            &.title__num-cols-#{$num-cols} {
                .title--lead-row {
                    @include title--column-line($num-cols);
                }

                .title--lead-col {
                    @include mq('>sm') {
                        columns: $num-cols;
                    }
                }
            }
        }

        &.title__doctor-detail {
            .title--prefix {
                margin-bottom: 0;
            }

            .title--separator {
                margin-bottom: 8px;
                color: $c--brand-light-blue;
            }
        }

        &.title__in-list {
            .title--separator {
                margin-bottom: 36px;
            }
        }

        &.title__in-cms {
            .title--separator {
                margin-bottom: 32px;
            }
        }
    }

    // States
}
