.legal-footer {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index('footer-nav');
        position: relative;
        padding: 20px 0;
        background-color: $c--brand-dark-gray-blue;
        color: $c--monochrome-white;

        .legal-footer--row {
            justify-content: center;

            @include mq('<=md') {
                padding: 22px 0;
            }
        }

        .legal-footer--col {
            @include grid--span((ratio: 1/1, mq: '<=md'));
        }
    }

    // Facets

    // States
}
