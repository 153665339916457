.news-card {
    // Vars
    $sz--image: 66px;

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        width: 100%;
        color: $c--monochrome-gray;
        background-color: $c--monochrome-white;
        transition: box-shadow $trs--base;

        .news-card--image-wrap {
            display: block;
            overflow: hidden;
        }

        .news-card--image-wrap-link {
            position: relative;
            display: block;
            padding-top: 9 / 16 * 100%;
            transition: transform $trs--base;
        }

        .news-card--image {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            transform: translate(-50%, -50%) scale(1.005);
        }

        .news-card--content {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 0 auto;
            justify-content: space-between;
            padding: 24px;
        }

        .news-card--date {
            @include type--copy-x-small();
            margin-bottom: 6px;
        }

        .news-card--title-link {
            @include type--copy();
            display: block;
            margin-bottom: 6px;
            color: $c--monochrome-dark-gray;
            transition: color $trs--base;
        }

        .news-card--copy {
            @include type--copy-x-small();
        }

        .news-card--text-wrap {
            padding-bottom: 16px;
        }

        .news-card--link-wrap {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
        }
    }

    // Facets

    // States
    & {
        & {
            box-shadow: 0 0 20px 0 rgba($c--brand-dark-blue, 0.33);
        }

        &:hover {
            box-shadow: 0 0 20px 0 rgba($c--brand-dark-blue, 1);
        }

        &.news-card__has-link.news-card__has-hover {
            // NOTE: Active if (touchevents == false) and (hover == true)
            @include has-support((touchevents: false)) {
                .news-card--image-wrap-link {
                    transform: scale(1.05);
                }

                .news-card--title-link {
                    color: $c--brand-light-blue;
                }
            }

            @include has-support((touchevents: true)) {
                .news-card--image-wrap-link {
                    transform: scale(1.05);
                }

                .news-card--title-link:active {
                    color: $c--brand-light-blue;
                }
            }
        }
    }
}
