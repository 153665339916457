.mobile-nav-list {
    // Vars
    $sz--button: 68px;

    // Support

    // Module
    & {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        height: 100%;

        .mobile-nav-list--menu {
            flex: 0 1 100%;
            padding: 40px 0;
            background-color: $c--monochrome-white;

            @include grid--fix-ie-flex-basis() {
                height: 100%;
            }
        }

        .mobile-nav-list--language {
            margin-top: 20px;
            border-top: 1px solid $c--brand-light-blue;
        }

        .mobile-nav-list--link {
            @include ff--base-semi-bold;
            display: block;
            padding: 20px 0;
            text-align: center;
            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--brand-light-blue;
            }

            &.u-router-link-active {
                color: $c--brand-light-blue;
                cursor: default;
            }
        }

        .mobile-nav-list--link-text-wrapper {
            position: relative;
            display: inline-block;
            padding: 2px 0;
        }
    }

    // Facets

    // States
}
