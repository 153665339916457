.table-col.table-col__actions {
    // Vars

    // Support

    // Module
    & {
        .table-col--icon {
            pointer-events: none;
            color: $c--brand-light-blue;
        }

        .table-col--bg {
            @include overlay();
        }

        .table-col--actions {
            display: flex;
            flex-flow: row nowrap;
        }

        .table-col--action {
            z-index: 1;
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;

            @include has-focus(true) {
                .table-col--icon {
                    color: $c--brand-dark-blue;
                    transform: scale(1.5);
                }
            }
        }
    }

    // Facets

    // States
}
