/// Field styles
///
@mixin form-field--primary-report-form() {
    // Vars

    // Support

    // Module

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary--report-form--base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-report-form-focus();
        }
    }
}

/// Base
///
@mixin form-field--primary--report-form--base() {
    .form-field--title-label.form-field--title-label__top {
        color: $c--brand-light-blue;
    }
}

/// Focus
///
@mixin form-field--primary-report-form-focus() {
    .form-field--title-label.form-field--title-label__top {
        color: $c--brand-blue;
    }
}
