.cui-message-bubble {
    // Vars

    // Support

    // Module
    & {
        overflow: visible;
        position: relative;

        .cui-message-bubble--sender {
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;
            color: $c--monochrome-white;
            margin: 0 ($sz--bubble-padding-x / 2) 6px;
        }

        .cui-message-bubble--wrap {
            overflow: visible;
            transform: rotateZ(1turn);
            transition: opacity $trs--cui-message-edit, max-height $trs--cui-message-edit;

            //background-color: rgba($c--monochrome-white, 0.2); /* DEBUG */
        }

        .cui-message-bubble--editor {
            //background-color: $c--brand-dark-blue; /* DEBUG */
        }

        .cui-message-bubble--display {
            display: flex;
            flex-flow: row nowrap;
            //background-color: $c--brand-salmon; /* DEBUG */
        }
    }

    // Facets
    & {
        &.cui-message-bubble__odc {
            text-align: left;

            .cui-message-bubble--display {
                justify-content: flex-start;
            }
        }

        &.cui-message-bubble__me {
            text-align: right;

            .cui-message-bubble--display {
                justify-content: flex-end;
            }
        }
    }

    // States
}
